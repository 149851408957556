import { LitElement, html, css } from "lit";
import { customElement, property, state, query } from "lit/decorators.js";
import { sanitize } from "dompurify";
import { unsafeHTML } from "lit/directives/unsafe-html.js";
import { shared } from "../styles";

@customElement("ptc-rich-content")
export class RichContent extends LitElement {
    @property()
    content: string;

    @property({ type: Number })
    previewHeight?: number;

    @property({ type: Boolean })
    expanded = false;

    @state()
    private _innerHeight: number = 0;

    @query(".inner")
    private _inner: HTMLDivElement;

    updated() {
        this.style.maxHeight = this.previewHeight && !this.expanded ? `${this.previewHeight}px` : "none";
        this._innerHeight = this._inner.offsetHeight;
        this._inner.style.paddingBottom = this.previewHeight && this.previewHeight < this._innerHeight ? "1.5em" : "0";
    }

    static styles = [
        shared,
        css`
            :host {
                display: block;
                position: relative;
                cursor: text;
                overflow: hidden;
            }

            ul {
                list-style: disc outside;
                padding-left: 1em;
            }

            h1,
            h2,
            h3,
            h4 {
                margin: 0 0 0.5em 0;
            }

            h1:not(:first-child),
            h2:not(:first-child),
            h3:not(:first-child),
            h4:not(:first-child) {
                margin-top: 0.5em;
            }

            p {
                margin: 0.5em 0;
            }

            p:first-child {
                margin-top: 0;
            }

            p:last-child {
                margin-bottom: 0;
            }

            em {
                font-style: italic;
            }

            .show-more {
                position: absolute;
                bottom: -1em;
                left: 0;
                right: 0;
                cursor: pointer;
                background: var(--color-bg);
                border-top: solid 1px var(--shade-2);
            }

            .show-more button {
                background: var(--color-bg);
                position: relative;
                top: -1em;
            }
        `,
    ];

    render() {
        return html`
            <div class="inner">${unsafeHTML(sanitize(this.content))}</div>
            ${!this.previewHeight || this.previewHeight > this._innerHeight
                ? ""
                : !this.expanded
                  ? html`
                        <div class="show-more horizontal layout">
                            <div class="stretch"></div>
                            <button class="skinny" @click=${() => (this.expanded = true)}>
                                <i class="arrow-down"></i> Mehr Anzeigen <i class="arrow-down"></i>
                            </button>
                            <div class="stretch"></div>
                        </div>
                    `
                  : html`
                        <div class="show-more horizontal layout">
                            <div class="stretch"></div>
                            <button class="skinny" @click=${() => (this.expanded = false)}>
                                <i class="arrow-up"></i> Weniger Anzeigen <i class="arrow-up"></i>
                            </button>
                            <div class="stretch"></div>
                        </div>
                    `}
        `;
    }
}
