import "./spinner";
import { LitElement, html, css } from "lit";
import { customElement, property, query } from "lit/decorators.js";
import { shared, mixins } from "../styles";
import { triggerReflow } from "../lib/util";

@customElement("ptc-dialog")
export class Dialog<I, R> extends LitElement {
    @property({ attribute: false })
    input: I;

    @property({ type: Boolean, attribute: "prevent-dismiss" })
    preventDismiss: boolean = false;

    @property({ type: Boolean, attribute: "show-scrim", reflect: true })
    showScrim: boolean = true;

    @property({ attribute: false })
    anchor: HTMLElement | null = null;

    @property({ type: Boolean })
    loading: boolean = false;

    @property()
    loadingMessage = "";

    @property({ type: Boolean })
    isShowing: boolean = false;

    promise: Promise<R | undefined> | null = null;

    private _hideTimeout?: number;
    private _resolve: ((result?: R | PromiseLike<R>) => void) | null;

    @query(".inner")
    protected _inner: HTMLElement;

    @query(".outer")
    private _outer: HTMLElement;

    protected done(result?: R) {
        this._resolve && this._resolve(result);
        this._resolve = null;
        this.promise = null;
        this.hide();
    }

    protected _show() {
        clearTimeout(this._hideTimeout);
        this.style.display = "";
        triggerReflow(this);
        this.isShowing = true;
        this.setAttribute("open", "");

        // this.dispatch(this.open ? "dialog-open" : "dialog-close", { dialog: this }, true, true);
        if (this.anchor) {
            Object.assign(this._inner.style, {
                position: "absolute",
                width: "auto",
                maxWidth: "100%",
                height: "auto",
            });
            const { left, top } = this.anchor.getBoundingClientRect();
            const { width, height } = this._inner.getBoundingClientRect();
            Object.assign(this._inner.style, {
                top: Math.min(top, window.innerHeight - height) + "px",
                left: Math.min(left, window.innerWidth - width) + "px",
            });
            Object.assign(this._outer.style, {
                transformOrigin: `${left}px ${top}px`,
            });
        } else {
            this._inner && (this._inner.style.cssText = "");
            this._outer && (this._outer.style.cssText = "");
        }
    }

    private _outerClicked(e: Event) {
        if (!this._inner.contains(e.target as HTMLElement) && !this.contains(e.target as HTMLElement)) {
            this.dismiss();
        }
    }

    connectedCallback() {
        super.connectedCallback();
        this.style.display = "none";
        this.addEventListener("keydown", (e) => e.stopPropagation());
    }

    async showAt(anchor: HTMLElement, input: I = undefined as unknown as I) {
        this.anchor = anchor;
        return this.show(input);
    }

    async show(input: I = undefined as unknown as I) {
        this.input = input;
        await this.updateComplete;

        this._show();

        this.promise = new Promise<R>((resolve) => {
            this._resolve = resolve;
        });

        return this.promise;
    }

    hide() {
        this.removeAttribute("open");
        this._hideTimeout = window.setTimeout(() => {
            this.style.display = "none";
            this.isShowing = false;
        }, 400);
        // this.dispatch("dialog-close", { dialog: this }, true, true);
    }

    static styles = [
        shared,
        css`
            :host {
                display: block;
                ${mixins.fullbleed()};
                position: fixed;
                z-index: 100;
            }

            :host(:not([open])) {
                pointer-events: none;
            }

            .outer {
                ${mixins.fullbleed()};
                min-height: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                padding: 10px;
                box-sizing: border-box;
                transition:
                    transform 400ms cubic-bezier(0.6, 0, 0.2, 1),
                    opacity 400ms cubic-bezier(0.6, 0, 0.2, 1);
            }

            :host(:not([open])) .outer {
                opacity: 0;
                transform: translate3d(0, 0, 0) scale(0.8);
            }

            :host(:not([show-scrim])) {
                pointer-events: none;
            }

            .dialog-scrim {
                display: block;
                background: #fff;
                opacity: 0;
                transition: opacity 400ms cubic-bezier(0.6, 0, 0.2, 1);
                transform: translate3d(0, 0, 0);
                ${mixins.fullbleed()};
                position: fixed;
            }

            :host([open]) .dialog-scrim {
                opacity: 0.7;
            }

            .inner {
                position: relative;
                width: var(--dialog-width, 100%);
                height: var(--dialog-height, auto);
                ${mixins.scroll()};
                box-sizing: border-box;
                max-width: var(--dialog-max-width, 400px);
                max-height: 100%;
                z-index: 1;
                border-radius: 8px;
                background: white;
                box-shadow: rgba(0, 0, 0, 0.1) 0 1px 10px;
                overflow: hidden;
                pointer-events: auto;
            }
        `,
    ];

    render() {
        return html`
            <div class="dialog-scrim" ?hidden=${!this.showScrim}></div>

            <div class="outer" @click=${this._outerClicked}>
                <slot name="before"></slot>
                <div id="inner" class="inner">
                    ${this.renderContent()}

                    <div
                        class="fullbleed center-aligning center-justifying vertical layout scrim"
                        ?hidden=${!this.loading}
                    >
                        <ptc-spinner ?active=${this.loading}></ptc-spinner>
                        ${this.loadingMessage
                            ? html`
                                  <div
                                      class="text-centering double-margined semibold"
                                      style="background: rgba(255, 255, 255,  0.9); max-width: 18em;"
                                  >
                                      ${this.loadingMessage}
                                  </div>
                              `
                            : ""}
                    </div>
                </div>
            </div>
        `;
    }

    protected renderContent() {
        return html` <slot></slot> `;
    }

    dismiss() {
        if (!this.preventDismiss) {
            this.done();
        }
    }
}
