import { Company, Department, Employee, EmploymentType, EntityFilter, Position, TimeEntry, Venue } from "./model";
import { DateRange, day } from "./time";

export interface EntityFilterContext {
    company: Company;
    employee?: Employee;
    date?: string | DateRange;
    venue?: Venue | null;
    department?: Department | null;
    position?: Position | number | null;
    timeEntry?: TimeEntry;
}

export function matchesEmploymentType(employee: Employee, type: EmploymentType, date?: string | DateRange): boolean {
    if (!employee) {
        return false;
    }

    const contract = !date
        ? employee.currentContract
        : typeof date === "string"
          ? employee.getContractForDate(date)
          : employee.getAllContractsForRange(date);

    if (Array.isArray(contract)) {
        return contract.some((c) => c.employmentType === type);
    }

    return contract?.employmentType === type;
}

export function matchesFilter(
    filter: EntityFilter,
    { company, employee, date, venue, department, position, timeEntry }: EntityFilterContext
): boolean {
    if (timeEntry?.positionId && !position) {
        position = timeEntry.positionId;
    }

    if (timeEntry?.position && !position) {
        position = timeEntry.position;
    }

    if (typeof position === "number") {
        position = company.getPosition(position)?.position;
    }

    if (timeEntry?.employeeId && !employee) {
        employee = company.getEmployee(timeEntry.employeeId);
    }

    if (timeEntry && !date) {
        date = timeEntry.date;
    }

    if (["employeeId", "employeeTag", "staffNumber"].includes(filter.type) && timeEntry && !employee) {
        return false;
    }

    const positions = getPositionsByFilter(filter, company);

    switch (filter.type) {
        case "venue":
        case "department":
        case "position":
            return (
                (!venue ||
                    venue.departments.some((dep) =>
                        dep.positions.some((pos) => positions?.some((p) => p.id === pos.id))
                    )) &&
                (!department || department.positions.some((pos) => positions?.some((p) => p.id === pos.id))) &&
                (!position || positions?.some((p) => p.id === (position as Position).id)) &&
                (!employee || employee.positions.some((pos) => positions?.some((p) => p.id === pos.id)))
            );
        case "employmentType":
            if (!employee) {
                return true;
            }
            return matchesEmploymentType(employee, filter.value, date);
        case "employeeStatus":
            if (!employee) {
                return true;
            }
            return employee!.status === filter.value;
        case "employeeId":
            return (
                (!venue ||
                    venue.departments.some((dep) =>
                        dep.positions.some((pos) => positions?.some((p) => p.id === pos.id))
                    )) &&
                (!department || department.positions.some((pos) => positions?.some((p) => p.id === pos.id))) &&
                (!position || positions?.some((p) => p.id === (position as Position).id)) &&
                (!employee || employee.id === filter.value)
            );
        case "staffNumber":
            return (
                (!venue ||
                    venue.departments.some((dep) =>
                        dep.positions.some((pos) => positions?.some((p) => p.id === pos.id))
                    )) &&
                (!department || department.positions.some((pos) => positions?.some((p) => p.id === pos.id))) &&
                (!position || positions?.some((p) => p.id === (position as Position).id)) &&
                (!employee || employee.staffNumber === filter.value)
            );
        case "employeeTag":
            return !employee || employee.tags?.some((tag) => tag.id === filter.value);
        case "costCenter": {
            const constCenter = company.costCenters.find((cc) => cc.number === filter.value);
            if (!constCenter) {
                return false;
            }
            return matchesFilters(constCenter.entities, { company, employee, date, venue, department, position });
        }
    }
}

export function matchesFilters(filters: EntityFilter[], context: EntityFilterContext) {
    const filterTypes: {
        workspace: EntityFilter[];
        employee: EntityFilter[];
        employmentType: EntityFilter[];
        employeeStatus: EntityFilter[];
        employeeTag: EntityFilter[];
        costCenter: EntityFilter[];
    } = {
        workspace: [],
        employee: [],
        employmentType: [],
        employeeStatus: [],
        employeeTag: [],
        costCenter: [],
    };

    for (const filter of filters) {
        switch (filter.type) {
            case "venue":
            case "department":
            case "position":
                filterTypes.workspace.push(filter);
                break;
            case "employeeId":
            case "staffNumber":
                filterTypes.employee.push(filter);
                break;
            case "employmentType":
                filterTypes.employmentType.push(filter);
                break;
            case "employeeStatus":
                filterTypes.employeeStatus.push(filter);
                break;
            case "employeeTag":
                filterTypes.employeeTag.push(filter);
                break;
            case "costCenter":
                filterTypes.costCenter.push(filter);
                break;
        }
    }

    return [...Object.values(filterTypes)].every(
        (filters) => !filters.length || filters.some((filter) => matchesFilter(filter, context))
    );
}

export function getSpecificity(filters: EntityFilter[]) {
    const types = new Set(filters.map((f) => f.type));
    let score = types.size;
    if (types.has("employeeId")) {
        score += 10;
    }
    return score;
}

export function serializeFilters(filters: EntityFilter[]) {
    return btoa(JSON.stringify(filters)).replace(/=/g, "");
}

export function deserializeFilters(filters: string) {
    return JSON.parse(atob(filters)) as EntityFilter[];
}

export type EmployeeSortProperty =
    | "firstName"
    | "lastName"
    | "staffNumber"
    | "birthday"
    | "email"
    | "birthdayDate"
    | "contractEnd"
    | "employedDays";

export type EmployeeSortDirection = "ascending" | "descending";

export function getEmployeeSortProp(sortProperty: EmployeeSortProperty) {
    return (employee: Employee) => {
        switch (sortProperty) {
            case "birthdayDate":
                return employee.birthday?.slice(5) || "";
            case "contractEnd": {
                const latestContract = employee.latestContract;
                return latestContract ? latestContract?.end || "9999-12-31" : "0000-00-00";
            }
            case "employedDays": {
                const employedInterval = employee.employedInterval;
                if (!employedInterval) {
                    return 0;
                } else {
                    const diff = employedInterval[1].getTime() - employedInterval[0].getTime();
                    return diff >= 0 ? diff + 1 * day : 0; // add one day to account for the day of end date, ignore negative intervals
                }
            }
            default: {
                const value = employee[sortProperty as keyof Employee];
                if (typeof value === "string") {
                    return value.trim();
                } else if (typeof value === "number") {
                    return value;
                }
                return "";
            }
        }
    };
}

/**
 * Optimizes sorting by properties that might have complicated calculations
 * by pre-calculating the sort properties and sorting by them.
 * The tradeoff is that we instantiate 3 new arrays and 2 new functions and NOT mutating the input array in place.
 */
export function sortBy<TEntry, TSortProp extends string | number>(
    arr: TEntry[],
    fnBy: (val: TEntry, index: number) => TSortProp,
    sortDirection: "ascending" | "descending"
): TEntry[] {
    // Pre-calculate sort properties once
    const sortProps: (string | number)[] = new Array(arr.length);
    const indices = new Array(arr.length);
    for (let i = 0; i < arr.length; i++) {
        sortProps[i] = fnBy(arr[i], i);
        indices[i] = i;
    }

    // sort indices by sort properties
    indices.sort((indexA, indexB) => {
        const aVal = sortProps[indexA];
        const bVal = sortProps[indexB];
        return aVal < bVal ? -1 : aVal > bVal ? 1 : 0;
    });

    // create new array with sorted entries
    const ascending = sortDirection === "ascending";
    const result: TEntry[] = new Array(arr.length);
    for (let i = 0; i < arr.length; i++) {
        result[i] = ascending ? arr[indices[i]] : arr[indices[arr.length - i - 1]];
    }

    return result;
}

function getPositionsByFilter(filter: EntityFilter, company: Company): Position[] {
    switch (filter.type) {
        case "venue":
            return company.getVenue(filter.value)?.departments.flatMap((dep) => dep.positions) ?? [];
        case "department":
            return company.getDepartment(filter.value).department?.positions ?? [];
        case "position": {
            const position = company.getPosition(filter.value)?.position;
            return position ? [position] : [];
        }
        case "employeeId":
            return company.getEmployee(filter.value)?.positions ?? [];
        case "staffNumber":
            return company.getEmployeeByStaffNumber(filter.value)?.positions ?? [];
        case "employeeStatus":
        case "employmentType":
        case "employeeTag":
        case "costCenter":
            return [];
    }
}
