import { html, css } from "lit";
import { customElement, queryAll, state } from "lit/decorators.js";
import { mixins } from "../../styles";
import { Dialog } from "../dialog";

@customElement("ptc-jobs-images-dialog")
export class ImagesDialog extends Dialog<{ images: string[]; index: number }, void> {
    @state()
    private _images: string[] = [];

    @state()
    private _index: number = 0;

    @queryAll(".image-container")
    private _imageContainers: HTMLDivElement[];

    protected firstUpdated() {
        this._inner.addEventListener("scroll", () => this._scroll());
    }

    async show({ images, index }: { images: string[]; index: number }) {
        this._images = images;
        const promise = super.show();
        await this.updateComplete;
        this._index = index;
        this._scrollToIndex(index);
        return promise;
    }

    private _scroll() {
        const width = window.innerWidth;
        this._index = Math.floor((this._inner.scrollLeft + width / 2) / width);
    }

    private _scrollToIndex(index: number) {
        const container = this._imageContainers[index];
        container?.scrollIntoView();
    }

    static styles = [
        ...Dialog.styles,
        css`
            .inner {
                background: none;
                box-shadow: none;
                border-radius: 0;
                ${mixins.fullbleed()};
                max-width: none;
                overflow-x: auto;
                scroll-behavior: smooth;
                scroll-snap-type: x mandatory;
                overscroll-behavior-block: contain;
            }

            .dialog-scrim {
                background: #333;
            }

            :host([open]) .dialog-scrim {
                opacity: 1;
            }

            .images {
                display: flex;
                flex-direction: row;
            }

            .image-container {
                scroll-snap-align: center;
                width: 100%;
                height: 100%;
                flex: none;
            }

            .image-container img {
                position: absolute;
                width: 100%;
                height: 100%;
                object-fit: contain;
                object-position: center center;
            }

            .close-button,
            .next-button,
            .prev-button {
                position: fixed;
                background: #333 !important;
                width: 2.5em;
                height: 2.5em;
            }

            .close-button:not(:hover),
            .next-button:not(:hover),
            .prev-button:not(:hover) {
                color: var(--color-bg) !important;
            }

            .close-button {
                top: 1em;
                right: 1em;
            }

            .next-button {
                right: 1em;
                top: 0;
                bottom: 0;
                margin: auto;
            }

            .prev-button {
                left: 1em;
                top: 0;
                bottom: 0;
                margin: auto;
            }
        `,
    ];

    renderContent() {
        return html`
            <div class="images">
                ${this._images.map(
                    (img) => html`
                        <div class="image-container">
                            <img src=${img} />
                        </div>
                    `
                )}
            </div>

            <button class="subtle large icon close-button" @click=${() => this.done()}>
                <i class="times"></i>
            </button>

            <button
                class="subtle large icon prev-button"
                @click=${() => this._scrollToIndex(this._index - 1)}
                ?hidden=${this._index === 0}
            >
                <i class="arrow-left"></i>
            </button>

            <button
                class="subtle large icon next-button"
                @click=${() => this._scrollToIndex(this._index + 1)}
                ?hidden=${this._index === this._images.length - 1}
            >
                <i class="arrow-right"></i>
            </button>
        `;
    }
}
